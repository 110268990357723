import MCPage from './page';
import { LitElement, html, css } from 'lit-element';
import '../components/main-layout';
import '../components/main-nav';

class PageHome extends MCPage {
  static get properties() {
    return {
      ...super.properties,
      navItems: { type: Array },
    };
  }

  constructor() {
    super();

    this.navItems = [
      {
        id: 'how-it-works',
        href: '#how-it-works',
        name: 'How It Works',
      },
      {
        id: 'pricing',
        href: '#pricing',
        name: 'Pricing',
      },
      ,
      {
        id: 'about',
        href: '#about',
        name: 'About',
      },
    ];
  }

  render() {
    return html`
      <style>
        :host {
          display: contents;
        }

        mc-main-layout::part(footer) {
          margin-top: 0;
        }
      </style>

      <mc-main-layout @mc-nav=${this.onNav}>
        <div id="home" class="relative bg-white overflow-hidden">
          <div class="max-w-screen-xl mx-auto">
            <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20">
              <mc-main-nav
                .user=${this.user}
                .items=${this.navItems}
                class="fixed left-0 top-0 right-0 z-20 shadow-sm bg-white"
              ></mc-main-nav>
              <main class="pt-6 md:pt-10 px-4 sm:px-6 mt-20">
                <div class="mx-auto max-w-screen-xl px-4 sm:px-6">
                  <div class="text-center">
                    <h2
                      class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
                    >
                      Create instant
                      <br class="sm:hidden" />
                      meeting
                      <br class="hidden sm:inline" />
                      spaces
                      <br class="sm:hidden" />
                      <span class="text-orange-600">for any event</span>
                    </h2>
                    <p
                      class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
                    >
                      MeetSpace lets you and your attendees easily chat, discuss
                      and share with each other before, during and after your
                      event - whether it's virtual, in-person or hybrid. Try it
                      today for your meetings, webinars, or conferences.
                    </p>
                    <div class="mt-5 sm:mt-8 sm:flex sm:justify-center">
                      <div>
                        <a
                          href="#"
                          class="mc-button primary w-full"
                          @click=${(event) => {
                            event.preventDefault();

                            event.target.dispatchEvent(
                              new CustomEvent('mc-nav', {
                                bubbles: true,
                                composed: true,
                                detail: {
                                  id: 'how-it-works',
                                },
                              })
                            );
                          }}
                        >
                          How it works
                        </a>
                      </div>
                      <div class="mt-3 sm:mt-0 sm:ml-3">
                        <a href="/register" class="mc-button secondary w-full">
                          Start now for free
                        </a>
                      </div>
                    </div>
                    <div class="text-base mt-4 ">
                      <a href="#" class="text-gray-900">
                        Any questions?
                        <button
                          class="text-orange-600"
                          @click=${this.onContactUs}
                        >
                          Get in touch
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="mt-20">
                  <img class="" src="/images/hero.png" alt="" />
                </div>
              </main>
            </div>
          </div>
        </div>

        <div id="how-it-works" class="bg-gray-50">
          <div
            class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl py-24 overflow-hidden"
          >
            <svg
              class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              />
            </svg>

            <div class="relative">
              <div class="max-w-md mx-auto space-y-2 lg:max-w-3xl text-center">
                <h2
                  class="text-lg leading-6 font-semibold text-orange-600 uppercase tracking-wider"
                >
                  How it works
                </h2>
                <p
                  class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none"
                >
                  Easy to set up, easy to use
                </p>
              </div>
            </div>

            <div
              class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
            >
              <div class="relative">
                <h4
                  class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9"
                >
                  For attendees
                </h4>
                <p class="mt-3 text-lg leading-7 text-gray-500">
                  MeetSpace provides you with a unique link for each event that
                  you can send out to attendees however you choose. On visiting
                  the link attendees are simply asked to enter their name and
                  confirm their email address - no other account setup or
                  password is required.
                </p>

                <ul class="mt-10">
                  <li>
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Frictionless access
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          Attendees don't need to have an existing account or
                          sign up for a new service - once they've confirmed
                          their email address they have full access.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Meet and discuss
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          Attendees can meet and chat directly with everyone
                          else attending, and you can set up as many discussion
                          spaces as you like to focus on particular topics.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Upload and share files
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          Attendees can upload and share presentations, videos
                          and images with each other individually or within
                          spaces.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Access anywhere
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          Attendees can join and use the full service on mobile,
                          tablet or desktop.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0">
                <svg
                  class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        class="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  class="relative mx-auto"
                  width="490"
                  src="/images/feature-1.png"
                  alt=""
                />
              </div>
            </div>

            <div class="relative mt-12 sm:mt-16 lg:mt-24">
              <div
                class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center"
              >
                <div class="lg:col-start-2">
                  <ul class="mt-10">
                    <li>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Timelines
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            Attendees can see at a glance the current and
                            upcoming schedule in their local time zone, and join
                            Zoom, Teams, or any other meeting service directly.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Notifications
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            Attendees can optionally receive mobile, desktop and
                            email notifications to get the latest updates from
                            you and chat notifications from fellow attendees.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Install
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            Attendees can optionally install the service to
                            their home screen or desktop directly from the web
                            site - no app store involved.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Before, during and after
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            Attendees can use the service for a period of 4
                            weeks before the event starts through to 4 weeks
                            after it has ended.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <img
                    class="relative mx-auto"
                    width="490"
                    src="/images/feature-2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
            >
              <div class="relative">
                <h4
                  class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9"
                >
                  For organisers
                </h4>
                <p class="mt-3 text-lg leading-7 text-gray-500">
                  As the event organiser you have full control over how the
                  service looks and works:
                </p>

                <ul class="mt-10">
                  <li>
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <!-- Heroicon name: lightning-bolt -->
                          <svg
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M13 10V3L4 14h7v7l9-11h-7z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Simple setup
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          You can setup the service in a matter of minutes and
                          invite attendees straight away.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Customise
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          You can personalise the service with your own icon,
                          colour scheme and profile questions.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Private and secure
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          You can control who has access, and attendee's details
                          are never shared with 3rd parties or used by us for
                          any reason other than authentication.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div
                          class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="h-6 w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4">
                        <h5 class="text-lg leading-6 font-medium text-gray-900">
                          Notifications
                        </h5>
                        <p class="mt-2 text-base leading-6 text-gray-500">
                          You can broadcast messages and updates directly to all
                          attendees which they will receive as mobile, desktop
                          or email notifications if enabled.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0">
                <img
                  class="relative mx-auto"
                  width="490"
                  src="/images/feature-3.png"
                  alt=""
                />
              </div>
            </div>

            <svg
              class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>

            <div class="relative mt-12 sm:mt-16 lg:mt-24">
              <div
                class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center"
              >
                <div class="lg:col-start-2">
                  <ul class="mt-10">
                    <li>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Install
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            You can get your icon on attendees home screens and
                            desktops when attendees optionally install the
                            service - no app required.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Moderation
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            You can moderate attendees and their posts to
                            prevent unwanted messages.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Timelines
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            You can add entries in your timeline to inform
                            attendees of the event schedule where they can see
                            at a glance what's happening now and next.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <div
                            class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="h-6 w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="ml-4">
                          <h5
                            class="text-lg leading-6 font-medium text-gray-900"
                          >
                            Integrate with Zoom, Teams and more
                          </h5>
                          <p class="mt-2 text-base leading-6 text-gray-500">
                            You can simply provide the meeting link in your
                            timeline and attendees will be able to join
                            directly.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg
                    class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          class="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    />
                  </svg>
                  <img
                    class="relative mx-auto"
                    width="490"
                    src="/images/feature-4.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="pricing" class="bg-gray-200">
          <div class="pt-24">
            <div
              class="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8"
            >
              <div class="max-w-md mx-auto space-y-2 lg:max-w-3xl">
                <h2
                  class="text-lg leading-6 font-semibold text-orange-600 uppercase tracking-wider"
                >
                  Pricing
                </h2>
              </div>
            </div>
            <div class="mt-8 bg-white sm:mt-12 ">
              <div class="relative">
                <div class="absolute inset-0 h-1/2 bg-gray-200"></div>
                <div
                  class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8"
                >
                  <div
                    class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex"
                  >
                    <div class="flex-1 bg-white px-6 py-8 lg:p-12">
                      <h3
                        class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9"
                      >
                        Only pay for the number of attendees that join
                      </h3>
                      <p class="mt-6 text-base leading-6 text-gray-500">
                        Pricing is based on the number of attendees at your
                        event - upgrade at any time.
                      </p>
                      <div class="mt-8">
                        <div class="flex items-center">
                          <h4
                            class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-orange-600"
                          >
                            What's included
                          </h4>
                          <div class="flex-1 border-t-2 border-gray-200"></div>
                        </div>
                        <ul
                          class="mt-8 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
                        >
                          <li class="flex items-start lg:col-span-1">
                            <div class="flex-shrink-0">
                              <!-- Heroicon name: check-circle -->
                              <svg
                                class="h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-sm leading-5 text-gray-700">
                              First 5 attendees free
                            </p>
                          </li>
                          <li
                            class="mt-5 flex items-start lg:col-span-1 lg:mt-0"
                          >
                            <div class="flex-shrink-0">
                              <!-- Heroicon name: check-circle -->
                              <svg
                                class="h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-sm leading-5 text-gray-700">
                              Premium support
                            </p>
                          </li>
                          <li
                            class="mt-5 flex items-start lg:col-span-1 lg:mt-0"
                          >
                            <div class="flex-shrink-0">
                              <!-- Heroicon name: check-circle -->
                              <svg
                                class="h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-sm leading-5 text-gray-700">
                              No minimum number of attendees
                            </p>
                          </li>
                          <li
                            class="mt-5 flex items-start lg:col-span-1 lg:mt-0"
                          >
                            <div class="flex-shrink-0">
                              <!-- Heroicon name: check-circle -->
                              <svg
                                class="h-5 w-5 text-green-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-sm leading-5 text-gray-700">
                              Service active 4 weeks before and after event
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
                    >
                      <p class="text-lg leading-6 font-medium text-gray-900">
                        Pay as you go
                      </p>
                      <div
                        class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900"
                      >
                        <span>&pound;3</span>
                        <span
                          class="ml-3 text-xl leading-7 font-medium text-gray-500"
                        >
                          / attendee
                        </span>
                      </div>
                      <div class="mt-6">
                        <div class="rounded-md shadow">
                          <a
                            href="/register"
                            class="mc-button primary w-full flex-col"
                          >
                            Get started for free
                            <span class="text-sm mt-1 text-orange-200"
                              >No obligation</span
                            >
                          </a>
                        </div>
                      </div>
                      <div class="mt-4 text-sm leading-5">
                        <a href="#" class="font-medium text-gray-900">
                          Discounts available for 100+ attendees
                        </a>
                      </div>
                      <div
                        class="mt-2 pt-2 border-t border-gray-300 text-sm leading-5"
                      >
                        <a href="#" class="font-medium text-gray-900">
                          Any questions?
                          <button
                            class="text-orange-600"
                            @click=${this.onContactUs}
                          >
                            Get in touch
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="about" class="bg-white">
          <div
            class="max-w-screen-xl mx-auto py-24 px-4 text-center sm:px-6 lg:px-8"
          >
            <div class="space-y-12">
              <div
                class="max-w-3xl mx-auto space-y-2 lg:max-w-none text-center"
              >
                <h2
                  class="text-lg leading-6 font-semibold text-orange-600 uppercase tracking-wider"
                >
                  About
                </h2>
                <p
                  class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none"
                >
                  10 years providing event solutions
                </p>
              </div>
              <div
                class="grid grid-cols-1 gap-12 items-center lg:grid-cols-3 lg:gap-8 lg:text-left"
              >
                <div class="space-y-5 sm:space-y-4">
                  <p class="text-xl leading-7 text-gray-500">
                    We've been helping event organisers with tech for the last
                    decade. MeetSpace is the next step of that journey, designed
                    and built for the changing face of worldwide events.
                  </p>
                  <p class="text-xl">
                    <a href="#" class="text-gray-900">
                      Any questions?
                      <button
                        class="text-orange-600"
                        @click=${this.onContactUs}
                      >
                        Get in touch
                      </button>
                    </a>
                  </p>
                </div>
                <div class="lg:col-span-2">
                  <ul class="flex justify-around">
                    <li>
                      <div class="flex items-center space-x-4 lg:space-x-6">
                        <img
                          class="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                          src="/images/fb.jpg"
                          alt=""
                        />
                        <div
                          class="font-medium text-lg leading-6 space-y-1 text-left"
                        >
                          <h4>Francis Bacon</h4>
                          <p class="text-orange-600">Co-Founder</p>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="flex items-center space-x-4 lg:space-x-6">
                        <img
                          class="w-16 h-16 rounded-full lg:w-20 lg:h-20"
                          src="/images/ch.jpg"
                          alt=""
                        />
                        <div
                          class="font-medium text-lg leading-6 space-y-1 text-left"
                        >
                          <h4>Chris Haynes</h4>
                          <p class="text-orange-600">Co-Founder</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mc-main-layout>
    `;
  }

  onNav(event) {
    const { id, event: clickEvent } = event.detail;

    if (clickEvent) {
      clickEvent.preventDefault();
    }

    this.shadowRoot.querySelector(`#${id}`).scrollIntoView({
      behavior: 'smooth',
    });
  }

  onContactUs(event) {
    event.preventDefault();

    window.chaport.q('open');
  }
}

customElements.define('mc-page-home', PageHome);
